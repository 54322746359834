/**
 * SMF - Campaign jQuery Functions
 * This is the main jquery file for the frontend,
 * public side of the SMF application.
 *
 * @generates smf-core/assets/js/campaign.js
 * @since     2.0.0
 * @requires jquery
 */

import $ from 'jquery';
import '@fortawesome/fontawesome-pro/js/fontawesome';
import '@fortawesome/fontawesome-pro/js/brands';
import '@fortawesome/fontawesome-pro/js/light';

window.$ = $;

function getUrlVars() {
  const vars = [];

  let hash;
  const hashes = window.location.href
    .slice(window.location.href.indexOf('?') + 1)
    .split('&');
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}

$(document).ready(function() {
  const url_vars = getUrlVars();

  if (typeof url_vars.spotify_user !== 'undefined') {
    document.getElementById('spotify_user').value = url_vars.spotify_user;
    const emailField = document.getElementById('field_email_address')
      .parentNode;
    if (emailField.classList) {
      emailField.classList.add('hidden');
    } else {
      emailField.className += ' ' + 'hidden';
    }
    emailField.parentNode.removeChild(emailField);
  }

  if (typeof url_vars.ae_member_id !== 'undefined') {
    document.getElementById('ae_member_id').value = url_vars.ae_member_id;
  }

  /**
   * Select2
   */
  $('.select2').select2({
    tokenSeparators: [',']
  });

  setTimeout(function() {
    if ($('#select2-field_country_region-container').length) {
      if ($('#select2-field_state-container').length) {
        console.log('hi');
        $('#field_state').select2({
          disabled: true
        });
        $('#field_country_region').on('select2:select', function(e) {
          const countryCode = e.params.data.id;
          let states;
          $.ajax({
            url: ajaxurl,
            method: 'POST',
            dataType: 'JSON',
            data: {
              action: 'smf_get_states',
              country_code: countryCode,
              locale: 'en'
            },
            success(data) {
              states = data.result;
            },
            complete() {
              $('#field_state')
                .children('option')
                .remove();
              $('#field_state').html('<option disabled selected></option>');
              $('#field_state').select2({
                disabled: false,
                data: states
              });
            }
          });
        });
      }
    }
  }, 1500);

  /**
   * Datepicker
   */
  const dateFormat =
    $('.datetimepicker').length > 0
      ? $('.datetimepicker input').attr('data-date-format')
      : 'MM/DD/YYYY';
  if (dateFormat === 'MM/DD' || dateFormat === 'DD/MM') {
    $('.datetimepicker').addClass('datetimepicker-md');
    $('.datetimepicker').datetimepicker({
      ignoreReadonly: true,
      useCurrent: false,
      allowInputToggle: true,
      viewMode: 'months',
      dayViewHeaderFormat: 'MMMM',
      widgetPositioning: {
        horizontal: 'left',
        vertical: 'auto'
      }
    });
    $('.dow').hide();
  } else {
    $('.datetimepicker').datetimepicker({
      ignoreReadonly: true,
      minDate: '01/01/1900',
      allowInputToggle: true,
      viewMode: 'years',
      widgetPositioning: {
        horizontal: 'left',
        vertical: 'auto'
      }
    });
    const birthDatePicker = $('.datetimepicker').data('DateTimePicker');
    if (birthDatePicker) {
      birthDatePicker.maxDate(
        moment()
          .subtract(10, 'years')
          .endOf('day')
      );
      birthDatePicker.clear();
    }
  }

  /**
   * iFrame - Preserve ratio
   */
  const $iframes = $('iframe');
  $iframes.each(function() {
    $(this)
      .data('ratio', this.height / this.width)
      .removeAttr('width')
      .removeAttr('height');
  });
  $(window)
    .resize(function() {
      $iframes.each(function() {
        const width = $(this)
          .parent()
          .width();
        $(this)
          .width(width)
          .height(width * $(this).data('ratio'));
      });
    })
    .resize();

  /**
   * Facebook Share
   */
  $('.social-links .facebook').click(function() {
    const url = $(this).data('url');
    FB.ui(
      {
        method: 'share',
        href: url
      },
      function(response) {}
    );
  });

  /**
   * Twitter Share
   */
  $('.social-links .twitter').click(function(e) {
    e.preventDefault();
    const loc = $(this).attr('href');
    const title = escape($(this).attr('title'));
    window.open(
      `http://twitter.com/share?url=${loc}&text=${title}&`,
      'twitterwindow',
      `height=450, width=550, top=${$(window).height() / 2 - 225}, left=${$(
        window
      ).width() /
        2}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
    );
  });

  /**
   * Mailinglist Signup Requirements
   */
  const $minMailingLists = parseInt($('#mailinglist_signup_minimum').val());
  const $mailingList = $('.mailing-list').popover({
    trigger: 'manual',
    content: `Choose at least ${$minMailingLists} lists`
  });

  const requiredFieldsWrap = $(
    '.form-group.inline[data-content],.inline-touch[data-content]'
  );
  requiredFieldsWrap.each(function(i, v) {
    $(v).popover({
      placement: 'auto',
      trigger: 'manual'
    });
  });

  $(document).on('click', 'button[type="submit"]', function(event) {
    event.preventDefault();
    let pass = true;
    requiredFieldsWrap.each(function(i, v) {
      const name = $(v).data('name');
      const type = $(v).data('type');
      if (type === 'text') {
        var value = $(`input[name='${name}']`).val();
        if (value.length === 0) {
          pass = false;
          $(v).popover('show');
          setTimeout(function() {
            $(v).popover('hide');
          }, 2000);
        }
      } else if (type === 'checkbox') {
      } else if (type === 'select') {
        var value = $(`select[name='${name}']`).val();
        if (!value) {
          pass = false;
          $(v).popover('show');
          setTimeout(function() {
            $(v).popover('hide');
          }, 2000);
        }
      } else if (type === 'datepicker') {
        var value = $(`input[name='${name}']`).val();
        if (typeof value !== 'undefined' && value.length === 0) {
          pass = false;
          $(v).popover('show');
          setTimeout(function() {
            $(v).popover('hide');
          }, 2000);
        }
      } else if (type === 'terms') {
        var value = $('input[name=terms-of-service]')[0].checked;
        if (!value) {
          pass = false;
          $(v).popover('show');
          setTimeout(function() {
            $(v).popover('hide');
          }, 2000);
        }
      }
    });
    if (pass) {
      $('#newsletter').submit();
    }
  });

  /**
   * Form Submit
   */
  $('#newsletter').submit(function(event) {
    const $self = $(this);
    // Rule - Minimum Number of Mailing Lists
    if (
      $minMailingLists >
      $('input[name="mailing-list-id[]"]:checked').length +
        ($('input[name="default_mailing_list"]').length <=
          $('input[name="default_mailing_list"]:checked').length)
    ) {
      event.preventDefault();
      $mailingList.popover('show');
      return false;
    }
    $mailingList.popover('hide');

    event.preventDefault();

    // Data Prep - Salesforce date req mm/dd/yyyy
    if ($self.has('#field_dob')) {
      var $birthField = $self.find('#field_dob');
      var $birthField_placeholder = $birthField.attr('placeholder');
    } else if ($self.has('#field_birthdate')) {
      var $birthField = $self.find('#field_birthdate');
      var $birthField_placeholder = $birthField.attr('placeholder');
    }

    if (
      $birthField_placeholder == 'MM/DD/YYYY' ||
      $birthField_placeholder == 'MM/DD' ||
      $birthField_placeholder == 'DD/MM/YYYY' ||
      $birthField_placeholder == 'DD/MM' ||
      $birthField_placeholder == 'YYYY/MM/DD'
    ) {
      const $birthField_dates = $birthField.val().split('/');
      let new_date = '';

      switch ($birthField_placeholder) {
        case 'MM/DD/YYYY':
          new_date = `${$birthField_dates[2]}/${$birthField_dates[0]}/${
            $birthField_dates[1]
          }`;
          break;
        case 'DD/MM/YYYY':
          new_date = `${$birthField_dates[2]}/${$birthField_dates[1]}/${
            $birthField_dates[0]
          }`;
          break;
        case 'DD/MM':
          new_date = `${$birthField_dates[1]}/${$birthField_dates[0]}`;
          break;
        case 'YYYY/MM/DD':
        case 'MM/DD':
          new_date = $birthField.val();
          break;
      }
      console.log(new_date);
      $birthField.val(new_date);
    }

    const $submitBtn = $('.btnSubmit');
    $submitBtn.attr('disabled', 'disabled');
    $submitBtn.addClass('btnSubmit--loading');

    /* Debugging */
    $.ajax({
      type: 'POST',
      url: $self.find('#js_url').val(),
      data: $self.serialize(),
      xhrFields: {
        withCredentials: false
      },
      dataType: 'json', // what type of data do we expect back from the server
      encode: true,
      crossDomain: true,
      error(error) {
        const errorObj = JSON.parse(error.responseText);
        if (errorObj.state === 'invalid-email') {
          const $submitBtn = $('.btnSubmit');
          $submitBtn.removeAttr('disabled');
          $submitBtn.removeClass('btnSubmit--loading');
          const email = $('#field_email_address');
          email.popover({
            placement: 'auto',
            trigger: 'manual'
          });
          email.attr('data-content', errorObj.message);
          email.popover('show');
          setTimeout(function() {
            email.popover('hide');
          }, 2000);
        }
      }
    }).done(function(data) {
      console.log(data);
      if (data.state === 'thank-you') {
        $.ajax({
          url: ajaxurl,
          method: 'POST',
          data: {
            action: 'frontend_submit_legacy',
            state: 'thank-you',
            campaign: campaign_id
          },
          success(data) {
            $('.form-wrapper').html(data);
          }
        });
      }
    });
  }); // End Form Submit
});

$(document).ready(function() {
  function updateSpotifyMailingListSelection() {
    const $spotify_section = $('.spotify-section');
    const url_vars = getUrlVars();

    // Check if on presave
    if ($spotify_section.length) {
      const $spotify_mailinglist_checkbox = $(
        '.spotify-section #default_mailing_list'
      );
      const $spotifyLink = $('.js-spotifyLink');
      let $href = $spotifyLink.attr('href');

      if ($spotify_mailinglist_checkbox.is(':checked')) {
        if ($href.indexOf('&default_mailing_list_opt_in=1') == -1) {
          $spotifyLink.attr(
            'href',
            `${$spotifyLink.attr('href')}&default_mailing_list_opt_in=1`
          );
        }
      } else {
        $href = $href.replace('&default_mailing_list_opt_in=1', '');
        $spotifyLink.attr('href', $href);
      }
    } else if (url_vars.default_mailing_list_opt_in != undefined) {
      // Check for url
      const $default_mailing_list = $('#default_mailing_list');

      if (url_vars.default_mailing_list_opt_in == 'true') {
        $default_mailing_list.prop('checked', true);
        $default_mailing_list
          .parent()
          .parent()
          .remove();
      }
    }
  }

  $(document).on(
    'change',
    '.spotify-section #default_mailing_list',
    function() {
      updateSpotifyMailingListSelection();
    }
  );
  updateSpotifyMailingListSelection();

  $(document).on('click', '.js-social-share', function(e) {
    e.preventDefault();
    const link = $(this).attr('href');
    window.open(link, 'popupWindow', 'width=670,height=378,scrollbars=no');
  });
});
